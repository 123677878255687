.shop-browse-products {
    padding: 6px 0px 60px 0px;

    @include media-breakpoint-up(md) {
        padding: 20px 0px 60px 0px;
    }
    @include media-breakpoint-up(lg) {
        padding: 60px 0px 60px 0px;
    }

    .tab-menu {
        margin-bottom: 60px;
        text-align: center;

        .nav-pills {
            display: inline-flex;
            justify-content: center;
            margin: 0 auto;
            padding: 12px;
            gap: 9px;
            background: #FAFAFA;
            border-radius: 8px;

            .nav-link {
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #7A7A7A;
                padding: 8px 16px;

                @include media-breakpoint-up(md) {
                    padding: 8px 20px;
                }

                &.active {
                    color: #FFFFFF;
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
                }
            }
        }
    }
    .no-results {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 32px 0;

        h4 {
            font-size: 1rem;
        }
        img {
            width: 100%;
            max-width: 240px;
        }
    }
}

.browse-jewelry {
    .jewelry-coop-banner {
        max-width: 925px;
        margin: 0 auto;
    }
    .jewelry-coop-info {
        font-family: $fontMontserrat;
        font-size: 14px;
        color: #78808f;
        padding: 0;
        margin: 3rem 0;

        @include media-breakpoint-up(md) {
            padding: 0 64px;
        }
    }
}

.products-cards-wrap {
    margin: 60px 0 0 0;

    &> h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: $navyBlue;
    }
    .product-cards {
        margin: 28px 0 44px 0;

        .card {
            padding: 0;

            .card-body {
                .card-title {
                    text-align: left;
                }
                .card-text {
                    text-align: left;
                }
            }
        }
    }
}

.shop-browse-wrap {
    position: relative;
}

.shop-pagination {
    nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 90px;
    }
}

.shop-product-view {
    margin-top: 12px;

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }

    .breadcrumb {
        .breadcrumb-item {
            color: $navyBlue;
            font-family: $fontOpenSans;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px; /* 200% */

            a {
                text-decoration: none;
            }
            &.active {
                font-weight: 600;
            }
        }
    }
    .product-view-gallery {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;

        @include media-breakpoint-up(md) {
            margin-right: 26px;
        }

        .product-view-image {
            position: relative;
            width: 100%;
    
            &> a {
                display: block;
                position: relative;
                overflow: hidden;
    
                img {
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    transform: scale(1.01);
                }
            }
            .control-prev,
            .control-next {
                position: absolute;
                top: 0px;
                bottom: 0px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: none;
                outline: none;
            }
            .control-prev {
                left: 15px;
            }
            .control-next {
                right: 15px;
            }
        }
        .product-view-image-selection {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            flex-wrap: wrap;
            padding: 0px;
            gap: 18px;

            .image-thumb {
                position: relative;
                display: block;
                width: 100px;
                height: 100px;
                box-sizing: border-box;
                border-radius: 2px;
                transition: transform .2s;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    object-fit: cover;
                    border-radius: 2px;
                }
                &.active {
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        box-sizing: border-box;
                        border: 3px solid $blueNcs;
                        border-radius: 4px;
                        filter: drop-shadow(0px 4px 24px rgba(51, 51, 51, 0.16));
                    }
                }
                &:hover {
                    transform: scale(1.1);
                }
                &.loading {
                    &::after {
                        font-family: "Font Awesome 6 Free"; 
                        font-weight: 900; 
                        content: "\f110";
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        color: #FFFFFF;
                        -webkit-animation-name: fa-spin;
                        animation-name: fa-spin;
                        -webkit-animation-duration: var(--fa-animation-duration,2s);
                        animation-duration: var(--fa-animation-duration,2s);
                        -webkit-animation-iteration-count: var(--fa-animation-iteration-count,infinite);
                        animation-iteration-count: var(--fa-animation-iteration-count,infinite);
                        -webkit-animation-timing-function: var(--fa-animation-timing,linear);
                        animation-timing-function: var(--fa-animation-timing,linear);
                    }
                }
            }
        }
    }
    .product-view-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 44px;
        margin-top: 60px;

        @include media-breakpoint-up(xl) {
            margin-top: 0px;
        }

        h1 {
            font-size: 38px;
            font-style: normal;
            font-weight: 600;
            line-height: 48px; /* 126.316% */
            word-break: break-word;
            color: $navyBlue;
            margin: 0 0 32px 0;
        }
        .product-price {
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px; /* 103.846% */
            color: $navyBlue;
        }
        .subsection-title {
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 40px;
            letter-spacing: -0.02em;
            color: #444444;
        }
        .extra-info {
            font-size: 16px;
            color: #787878;
            margin: 10px 0 0 0;
        }
        .product-view-info-description {
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
            
            .product-meta-info {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 10px 18px 10px 20px;
                margin-bottom: 0;

                li {
                    position: relative;
                    list-style-type: none;
                    margin-bottom: 0;
                    padding: 5px 0px;
                    line-height: 1.4;
                    font-weight: 400;
                    font-size: 15px;
                    letter-spacing: 0;
                    color: $navyBlue;

                    &:before {
                        content: "";
                        display: block;
                        height: 15px;
                        width: 18px;
                        position: absolute;
                        left: -20px;
                        top: 7px;
                        background-image:url('./../images/misc/check.svg');
                        filter: invert(12%) sepia(10%) saturate(3521%) hue-rotate(181deg) brightness(96%) contrast(94%) !important;
                        background-repeat: no-repeat;
                        background-position: left top;
                    }
                    b {
                        font-weight: 600;
                    }
                }
            }
        }
        .product-view-parameters-cont {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 22px;
        }
        .product-view-parameters {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 42px;
            padding: 0;
            margin: 0;
            list-style: none;

            .parameter {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 6px;
                
                span {
                    color: $independenceGray;
                    font-family: $fontOpenSans;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px; /* 200% */
                }
                .parameter-value {
                    color: $navyBlue;
                    font-family: $fontMontserrat;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px; /* 150% */
                }
            }
        }
        .product-variants-dropdown {
            .dropdown-menu {
                overflow: hidden;
                overflow-y: auto;
                max-height: 60vh;
            }
        }
        .product-view-jewelry-integration {
            margin-top: 32px;
        }
        .small-product-preview {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 12px;
            padding: 12px 8px;

            img {
                width: 120px;
            }
            .variant-info {
                h6 {
                    margin-bottom: 0;
                }
                .variant-specs {
                    margin-bottom: 0;

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: #444;
                        display: inline;
                        margin-left: 12px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .change-integration-product-btn {
            cursor: pointer;
            text-decoration: none;
            position: relative;
            display: block;

            .loading {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 14px;
                padding: 28px 30px;
            }
            .overlay-label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                pointer-events: none;
                top: 12px;
                bottom: 12px;
                left: 8px;
                width: 120px;
                background-color: rgba(255, 255, 255, .7);
                font-weight: 600;
                font-size: 24px;
                color: $gray-600;
                opacity: 0;
                transition: opacity 300ms ease-in-out;
            }
            &:hover {
                .overlay-label {
                    opacity: 1;
                }
            }
        }
        .product-view-add-engraving {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding: 16px 0;
            border-top: 1px solid #F2F2F2;
            border-bottom: 1px solid #F2F2F2;
            text-align: left;

            .add-toggle {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 12px;
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: $navyBlue;
                text-decoration: none;
            }
            .filled-in-info {
                display: none;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 22px;
                width: 100%;

                .texts {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 4px;
                    flex-grow: 1;

                    .label {
                        font-family: $fontMontserrat;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: $navyBlue
                    }
                    .filled-in-content {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        color: $navyBlue;
                        margin: 0;
                    }
                }
                .links {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 22px;

                    a {
                        font-family: $fontMontserrat;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        color: $emeraldGreen;
                    }
                }
            }
            &.filled-in {
                .add-toggle {
                    display: none;
                }
                .filled-in-info {
                    display: flex;
                }
            }
        }
        .product-view-payment-section {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 40px;
            width: 100%;

            .product-view-info-buttons {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 0px;
                gap: 20px;

                .btn {
                    flex-grow: 1;
                    width: 100%;
                }
            }
            .product-view-payments-info {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 24px;

                .payment-providers {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    gap: 4px;
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        list-style-type: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 60px;
                        height: 30px;
                    
                        &> svg {
                            max-width: 40px;
                        }
                        &:first-child svg {
                            max-width: 55px;
                        }
                    }
                }
                .texts {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 6px;
                    padding: 0px;
                    margin-bottom: 0;

                    li {
                        list-style-type: none;
                        color: $navyBlue;
                        padding: 5px 3px;
                        margin-left: 0px;
                        line-height: 1.2;
                        font-weight: 500;
                        font-size: 12px;
                    
                        b {
                            font-weight: 600;
                        }
                        &.safe:before  {
                            content: "";
                            display: inline-block;
                            vertical-align: top;
                            height: 14px;
                            width: 18px;
                            margin-right: 4px;
                            margin-top: -1px;
                            background-image: url('./../images/misc/shield-check.svg');
                            filter: invert(64%) sepia(33%) saturate(6830%) hue-rotate(106deg) brightness(103%) contrast(82%) !important; 
                            background-repeat: no-repeat;
                            background-position: left top;
                        }
                        &.delivery-period:before {
                            content: "";
                            display: inline-block;
                            vertical-align: top;
                            height: 14px;
                            width: 18px;
                            margin-right: 4px;
                            margin-top: -1px;
                            background-image: url('./../images/misc/truck-fast.svg');
                            filter: invert(64%) sepia(33%) saturate(6830%) hue-rotate(106deg) brightness(103%) contrast(82%) !important; 
                            background-repeat: no-repeat;
                            background-position: left top;
                        }
                    }
                }
            }
        }
    }
    .product-view-bottom-wide {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
    }
    .mmctable-container {
        width: 100%;
        overflow-x: auto;
        margin: 60px 0 0;
    }
    .mmctable {
        width: 100%;
        border-collapse: separate; /* Use separate borders to apply border-radius */
        border-spacing: 0;
        border-radius: 10px;
        background-color: white; /* White background for the table */
        white-space: nowrap; /* Prevent wrapping of table contents */

        th, 
        td {
            padding: 8px 16px;
            text-align: left;
            border: 1px solid #17253f0a;
            color: #17253f;
            font-size: 15px;
        }
        thead {
            width: 100%;
            background-color: #17253f0a;
            
            th {
                position: sticky;
                top: 0;
                background-color: #17253f;
                color: #fff;
                font-size: 17px;

                &:first-child {
                    border-top-left-radius: 10px;
                }
                &:last-child {
                    border-top-right-radius: 10px;
                }
            }
        }
        tbody {
            width: 100%;

            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 10px;
                }
                td:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
            tr:nth-child(even) {
                background-color: #17253f0a;
            }
            tr:hover {
                background-color: #17253f0a;
            }
            .pm-name-column {
                background-color: #17253f; /* Set your desired background color */
                font-weight: 600;
                color: #fff
            }
            .mmctable-icon {
                filter: invert(9%) sepia(41%) saturate(1965%) hue-rotate(195deg) brightness(96%) contrast(90%);
                height: 15px ;
        
                &.inactive {
                    opacity: 20%;
                }
            }
        }
    }
    .mmctable-disclaimerbelow {
        display: block;
        margin-top: 5px;
        margin-bottom: 30px;
        color: #17253f75;
        font-size: 0.8em;
        line-height: 1.2;
    } 
}
.shop-product-attributes-large {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
    gap: 0px;

    @include media-breakpoint-up(md) {
        gap: 16px;
        padding-bottom: 0 !important;
    }
    &:last-child {
        padding: $vertical-section-spacing-sm 0px !important;

        @include media-breakpoint-up(md) {
            padding: $vertical-section-spacing-md 0px !important;
        }
        @include media-breakpoint-up(lg) {
            padding: $vertical-section-spacing-lg 0px !important;
        }
    }

    .product-attribute {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        gap: 10px;
        width: 32%;

        @include media-breakpoint-up(md) {
            width: 30%;
        }
        @include media-breakpoint-up(lg) {
            width: 118px;
        }
        @include media-breakpoint-up(xl) {
            width: 157px;
        }

        .attribute-text {
            color: $independenceGray;
            text-align: center;
            font-family: $fontOpenSans;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px; /* 150% */
            hyphens: auto;
            word-wrap: break-word;
            overflow-wrap: break-word;
            margin: 0;
        }
    }
}

.shop-purchase-steps {
    h3 {
        font-size: 25px;
        font-weight: 600;
        line-height: 32px; /* 128% */
        margin-bottom: 36px;

        @include media-breakpoint-up(md) {
            padding: 0 14px;
            max-width: 410px;
        }
    }
    .steps-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 24px;

        @include media-breakpoint-up(md) {
            row-gap: 12px;
        }

        .step {
            display: flex;
            width: 100%;
            min-height: auto;
            padding: 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            gap: 6px;
            background: $lightGray;

            @include media-breakpoint-up(md) {
                width: 49%;
            }
            @include media-breakpoint-up(lg) {
                width: 31%;
                min-height: 358px;
            }

            p {
                text-align: center;
                font-family: $fontOpenSans;
                font-size: 18px;
                margin: 0;
            }
            .step-number {
                color: $independenceGray;
                font-weight: 600;
                line-height: 25px; /* 138.889% */
            }
            .step-title {
                color: $navyBlue;
                font-size: 21px;
                font-weight: 600;
                line-height: 27px; /* 128.571% */
            }
            .step-description {
                color: $independenceGray;
                font-weight: 400;
                line-height: 36px; /* 200% */
            }
        }
    }
}

.shop-pro-explanation {
    position: relative;
    padding: 0 0 0 72px;

    &::before {
        content: "\f05a";
        display: block;
        font-family: 'Font Awesome 6 Free';
        font-size: 46px;
        color: #d7d7d7;
        position: absolute;
        left: 0px;
        top: 0;
    }
    h3 {
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px; /* 126.316% */
        color: #747474;
        hyphens: auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
        margin: 0 0 26px 0;
    }
    &> p {
        color: #727272;
        font-family: $fontOpenSans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        max-width: 760px;
        margin: 0;
    }
}

.exclusivity-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    @include media-breakpoint-up(lg) {
        gap: 62px;
    }

    .exclusivity-intro {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-self: stretch;
        height: auto;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
        @include media-breakpoint-up(xl) {
            height: 500px;
        }

        .text-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            width: auto;
            padding: 32px 0;

            @include media-breakpoint-up(md) {
                gap: 16px;
                padding: 48px 0;
            }
            @include media-breakpoint-up(lg) {
                width: 420px;
                padding: 12px 26px 12px 0;
            }
            @include media-breakpoint-up(xl) {
                padding: 48px 48px 48px 0;
            }

            h3 {
                font-size: 38px;
                font-style: normal;
                font-weight: 600;
                line-height: 48px; /* 126.316% */
                hyphens: auto;
                word-wrap: break-word;
                overflow-wrap: break-word;
                margin: 0;
            }
            p {
                color: $independenceGray;
                font-family: $fontOpenSans;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px; /* 166.667% */
                margin: 0;
            }
        }
        .carousel {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 5px;
            flex: 1 0 0;
            align-self: stretch;
            padding: 8px 0 0 0;

            .carousel-indicators {
                top: 0px;
                bottom: initial;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                align-self: stretch;
                margin: 0;

                [data-bs-target] {
                    flex: 1 0 0;
                    height: 3px;
                    background: $blueNcs;
                    margin: 0;
                    border: none;
                }
            }
            .carousel-inner {
                .carousel-item {
                    img {
                        object-fit: cover;

                        @include media-breakpoint-up(xl) {
                            height: 500px;
                        }
                    }
                }
            }
        }
    }
    .exclusivity-attributes {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        align-self: stretch;
        flex-wrap: wrap;
        row-gap: 24px;

        @include media-breakpoint-up(lg) {
            gap: 26px;
        }
        @include media-breakpoint-up(xl) {
            gap: 49px;
        }

        .attribute {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 48%;
            }
            @include media-breakpoint-up(lg) {
                width: auto;
                flex: 1 0 0;
            }

            p {
                font-family: $fontOpenSans;
                margin: 0;
            }
            .attribute-title {
                color: $navyBlue;
                font-size: 21px;
                font-weight: 600;
                line-height: 27px; /* 128.571% */
            }
            .attribute-description {
                color: $independenceGray;
                font-size: 18px;
                font-weight: 400;
                line-height: 36px; /* 200% */
            }
        }
    }
}

.shop-product-view-article,
.shop-browse-article,
.inpage-article {
    @include media-breakpoint-up(md) {
        gap: 44px;
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
    .blog-article {
        .article-content {
            margin-top: 16px;
        }
    }
}

.engraving-modal {
    .modal-content {
        max-width: 432px;

        .modal-header {
            padding: 24px 24px 12px;
        }
        .modal-body {
            padding: 0px 0px 30px 0px;

            .engraving-preview {
                position: relative;
                width: 100%;

                .engraving-preview-image {
                    width: 100%;
                }
                .draggable-wrap {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;

                    .draggable {
                        position: absolute;
                        display: inline-block;
                        max-width: 60%;
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        transform-origin: center;

                        &> p {
                            display: inline-block;
                            padding: 10px;
                            margin: 0;
                            font-family: $fontMontserrat;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 28px;
                            text-align: center;
                            color: $navyBlue;
                        }
                        .resizers {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            right: 0px;
                            bottom: 0px;

                            .resizer {
                                width: 7px;
                                height: 7px;
                                border-radius: 50%;
                                background: #D9D9D9;
                                border: 1px solid rgba(255, 255, 255, 0.2);
                                position: absolute;

                                &.top-left {
                                    left: -5px;
                                    top: -5px;
                                }
                                &.top-right {
                                    right: -5px;
                                    top: -5px;
                                }
                                &.bottom-left {
                                    left: -5px;
                                    bottom: -5px;
                                }
                                &.bottom-right {
                                    right: -5px;
                                    bottom: -5px;
                                }
                            }
                        }
                    }
                }
            }
            .engraving-inputs-wrap {
                padding: 30px 24px 0 24px;

                input[type="text"]::placeholder {
                    color: #CBCBCB;
                    font-weight: 400;
                }
                .form-group-file {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.shop-cart {
    margin-top: 44px;
    margin-bottom: 60px;

    .cart-product-list {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 0px;
        list-style: none;
        
        .cart-product {
            position: relative;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            padding: 24px 12px;
            gap: 12px;
            transition: $btn-transition;
    
            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                height: 1px;
                background: #EDEDED;
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
        }
        .btn-trash {
            font-size: 1.5rem;
            color: $midnightBlue;

            &:hover {
                color: shade-color($midnightBlue, 10%);
            }
        }
    }
    .cart-product-item,
    .cart-integrated-product {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;

        @include media-breakpoint-up(md) {
            align-items: center;
        }

        .cart-product-checkbox {
            display: none;
            width: 24px;
            height: 24px;
            border: 1.5px solid #ADC6C2;
            border-radius: 4px;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }
        .cart-product-thumbnail {
            width: 88px;
            height: 88px;
            border-radius: 4px;
            margin-top: 6px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }

            img {
                width: 88px;
                height: 88px;
                border-radius: 4px;
                object-fit: cover;
            }
        }
        .cart-product-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
            padding: 0px;

            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: $navyBlue;
                margin: 0; 
            }
            .product-price {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: $navyBlue;
                margin: 0;
            }
            .product-description {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #444444;
                margin: 0;
            }
            .item-error {
                font-size: 14px;
                color: $red;
                margin: 0;
            }
        }
        .btn-trash {
            display: none;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }
        .mobile-btn-trash {
            display: inline-block;
            text-decoration: none;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }
    .cart-integrated-products {
        position: relative;
        display: flex;
        width: auto;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 16px 0;
        gap: 24px;

        @include media-breakpoint-up(md) {
            width: calc(100% - 162px);
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 1px;
            background: #EDEDED;
        }
    }
    .cart-product-engraving {
        position: relative;
        display: flex;
        width: calc(100% - 162px);
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 16px 0;
        gap: 24px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 1px;
            background: #EDEDED;
        }
        .cart-engraving-checkbox {
            width: 24px;
            height: 24px;
            border: 1.5px solid #ADC6C2;
            border-radius: 4px;
        }
        .cart-engraving-info {
            h6 {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $navyBlue;
                margin: 0;

                b {
                    font-weight: 500;
                }
            }
            .engraving-price {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: $navyBlue;
                margin: 0;
            }
        }
    }
    .cart-payment-details,
    .cart-express-checkout {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 16px;
        gap: 12px;        
        border: 1px solid #EDEDED;
        border-radius: 4px;

        @include media-breakpoint-up(lg) {
            width: 288px;
        }
        
        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: $navyBlue;
            margin: 0;
        }
        hr {
            display: block;
            width: 100%;
            height: 1px;
            border: 1px solid #EDEDED;
            margin: 0;
            
            &.dashed {
                border: 1px dashed #EDEDED;
            }
        }
        .payment-method-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 16px;
            width: 100%;

            .texts {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                flex-grow: 1;

                span {
                    font-family: $fontMontserrat;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #444444;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $navyBlue;
                    margin: 0;
                }
            }
        }
        .payment-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;
            width: 100%;

            .label {
                font-family: $fontMontserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #444444;
                flex-grow: 1;
            }
            .value {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                color: $navyBlue;
                margin: 0;
            }
        }
        .btn {
            display: block;
            width: 100%;

            &:disabled {
                border-color: #999999;
                background-color: #999999;
            }
        }
    }
    .cart-pay-otherwise {
        font-size: 14px;
        color: #444;
        text-align: center;
        padding: 14px 0;

        @include media-breakpoint-up(lg) {
            width: 288px;
        }
    }
    .cart-express-checkout {
        position: relative;

        &.loading {
            @keyframes spinner {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
            &::after {
                content: "";
                display: block;
                text-align: center;
                border: 4px solid #f3f3f3;
                border-radius: 50%;
                border-top: 4px solid #3498db;
                width: 22px;
                height: 22px;
                -webkit-animation: spin 2s linear infinite; /* Safari */
                animation: spin 1s linear infinite;
                margin-left: calc(50% - 11px);
            }
        }
    }
}